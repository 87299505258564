// Импортируем другие js-файлы
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
"use strict";

document.addEventListener("DOMContentLoaded", function(event) {
               var onTransitionEnd = function(event) {
                 var body = document.body,
                   page = document.getElementById("page-wrapper");

if (this != event.target) return;

                 body.classList.remove("animating");
                 body.classList.remove("left");
                 body.classList.remove("right");

                 body.classList.toggle("menu-visible");

page.transitionend = page.webkitTransitionEnd = page.otransitionend = page.MSTransitionEnd = null;
               };



           // Run if toggle menu button was tapped or clicked
           document
             .getElementById("mobile-menu-toggle")
             .addEventListener("click", function(e) {
               e.preventDefault();

               var body = document.body,
                 page = document.getElementById("page-wrapper");

               // When the toggle menu link is clicked, animation starts
               body.classList.add("animating");

               // Set direction of the animation
               body.classList.add(
                 body.classList.contains("menu-visible") ? "right" : "left"
               );

               // add event listeners for transition end event

               if (page.addEventListener) {

                 page.addEventListener("transitionend", onTransitionEnd, false);
                 page.addEventListener(
                   "webkitTransitionEnd",
                   onTransitionEnd,
                   false
                 );
                 page.addEventListener(
                   "otransitionend",
                   onTransitionEnd,
                   false
                 );
                 page.addEventListener(
                   "MSTransitionEnd",
                   onTransitionEnd,
                   false
                 );
               } else {
                 page.attachEvent("transitionend", onTransitionEnd);
                 page.attachEvent("webkitTransitionEnd", onTransitionEnd);
                 page.attachEvent("otransitionend", onTransitionEnd);
                 page.attachEvent("MSTransitionEnd", onTransitionEnd);
               }
             });

           document
             .getElementById("mobile-menu-close")
             .addEventListener("click", function(e) {
               e.preventDefault();
               var body = document.body,
                 page = document.getElementById("page-wrapper");

               // When the toggle menu link is clicked, animation starts
               body.classList.add("animating");

               // Set direction of the animation
               body.classList.add(
                 body.classList.contains("menu-visible") ? "right" : "left"
               );
             });



             });
var slideWrapper = $(".main-slider"),
iframes = slideWrapper.find(".embed-player"),
lazyImages = slideWrapper.find(".slide-image"),
lazyCounter = 0;

var isPause;

// POST commands to YouTube or Vimeo API
function postMessageToPlayer(player, command) {
if (player == null || command == null) return;
player.contentWindow.postMessage(JSON.stringify(command), "*");
}

// When the slide is changing
function playPauseVideo(slick, control) {
var currentSlide, slideType, startTime, player, video;

currentSlide = slick.find(".slick-current");
slideType = currentSlide.attr("class").split(" ")[1];
player = currentSlide.find("iframe").get(0);
startTime = currentSlide.data("video-start");

if (slideType === "vimeo") {
  switch (control) {
    case "play":
      if (
        startTime != null &&
        startTime > 0 &&
        !currentSlide.hasClass("started")
      ) {
        currentSlide.addClass("started");
        postMessageToPlayer(player, {
          method: "setCurrentTime",
          value: startTime
        });
      }
      postMessageToPlayer(player, {
        method: "play",
        value: 1
      });
      break;
    case "pause":
      postMessageToPlayer(player, {
        method: "pause",
        value: 1
      });
      break;
  }
} else if (slideType === "youtube") {
  switch (control) {
    case "play":
      postMessageToPlayer(player, {
        event: "command",
        func: "mute"
      });
      postMessageToPlayer(player, {
        event: "command",
        func: "playVideo"
      });
      break;
    case "pause":
      postMessageToPlayer(player, {
        event: "command",
        func: "pauseVideo"
      });
      break;
  }
} else if (slideType === "video") {
  video = currentSlide.children("video").get(0);
  if (video != null) {
    if (control === "play") {
      video.play();
    } else {
      video.pause();
    }
  }
}
}

// Resize player
function resizePlayer(iframes, ratio) {
if (!iframes[0]) return;
var win = $(".main-slider"),
  width = win.width(),
  playerWidth,
  height = win.height(),
  playerHeight,
  ratio = ratio || 16 / 9;

iframes.each(function() {
  var current = $(this);
  if (width / ratio < height) {
    playerWidth = Math.ceil(height * ratio);
    current
      .width(playerWidth)
      .height(height)
      .css({
        left: (width - playerWidth) / 2,
        top: 0
      });
  } else {
    playerHeight = Math.ceil(width / ratio);
    current
      .width(width)
      .height(playerHeight)
      .css({
        left: 0,
        top: (height - playerHeight) / 2
      });
  }
});
}

// DOM Ready
$(function() {
// Initialize
slideWrapper.on("init", function(slick) {
  slick = $(slick.currentTarget);
  setTimeout(function() {
    playPauseVideo(slick, "play");
  }, 1000);
  resizePlayer(iframes, 16 / 9);
});
slideWrapper.on("beforeChange", function(event, slick) {
  slick = $(slick.$slider);
  playPauseVideo(slick, "pause");
});
slideWrapper.on("afterChange", function(event, slick) {
  slick = $(slick.$slider);
  playPauseVideo(slick, "play");
});
slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
  lazyCounter++;
  if (lazyCounter === lazyImages.length) {
    lazyImages.addClass("show");
    // slideWrapper.slick("slickPlay");
  }
});

var time = 20;
var $bar, tick, percentTime, $activeBar;

function getMinOfArray(numArray) {
  return Math.min.apply(null, numArray);
}

slideWrapper.on("init", function(event, slick, direction) {
  var videoList = document.querySelectorAll(".slide-video"),
    videoListTime = [];
  if (videoList.length) {
    videoList.forEach(function(entry, index) {

          entry.addEventListener("loadedmetadata", function() {

        videoListTimeNew = videoListTime.push(this.duration);
        if (index == videoList.length - 1) {
          var minTime = getMinOfArray(videoListTime) - 1;

          startProgressbar(minTime);
        }
      });
    });
  }
});

//start the slider
slideWrapper.slick({
  // fade:true,
  // autoplay:true,
  // autoplaySpeed: 6000,
  lazyLoad: "progressive",
  speed: 600,
  arrows: false,
  dots: true,
  cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
  draggable: false,
  swipe: false,
  swipeToSlide: false
});

// slideWrapper.on({
//   mouseenter: function() {
//     isPause = true;
//   },
//   mouseleave: function() {
//     isPause = false;
//   }
// });

$bar = $(".slick-dots>li button");

function startProgressbar(_time) {
  resetProgressbar();
  percentTime = 0;
  isPause = false;
  $(".slick-dots li[class!='slick-active'] button").css("opacity", "0");

  time = _time;

  tick = setInterval(interval, 10);
}

function interval() {
  if (isPause === false) {
    percentTime += 1 / (time + 0.1);
    $(".slick-dots .slick-active button").css({
      width: percentTime + "%",
      opacity: 1
    });
    if (percentTime >= 100) {
      slideWrapper.slick("slickNext");
      startProgressbar(time);
    }
  }
}

function resetProgressbar() {
  $(".slick-dots  button").css({
    width: 0 + "%",
    opacity: 0
  });
  clearTimeout(tick);
}

slideWrapper.on("afterChange", function(
  event,
  slick,
  currentSlide,
  nextSlide
) {
  $(".slick-dots li[class!='slick-active'] button").css("opacity", "0");
var currentVideo = slideWrapper
.find("[data-slick-index=" + currentSlide + "]")
.find("video");

currentVideo[0].currentTime = 1



// currentVideo[0].addEventListener(
//   "play",
//   function() {
//
//   },
//   false
// );

});



//startProgressbar();
});

// Resize event
$(window).on("resize.slickVideoPlayer", function() {
resizePlayer(iframes, 16 / 9);
});


var vis = (function() {
var stateKey,
  eventKey,
  keys = {
    hidden: "visibilitychange",
    webkitHidden: "webkitvisibilitychange",
    mozHidden: "mozvisibilitychange",
    msHidden: "msvisibilitychange"
  };
for (stateKey in keys) {
  if (stateKey in document) {
    eventKey = keys[stateKey];
    break;
  }
}
return function(c) {
  if (c) {
    document.addEventListener(eventKey, c);
    //document.addEventListener("blur", c);
    //document.addEventListener("focus", c);
  }
  return !document[stateKey];
};
})();

vis(function() {
var videoList = document.querySelectorAll(".slide-video");

if(vis()){
            videoList.forEach(function(entry, index) {
                        entry.play();
});
          } else {
            videoList.forEach(function(entry, index) {
                          entry.pause();
});

              }
});
function adjustHeightToMax(selector) {
  var mainDivs = document.querySelectorAll(selector);
  var maxHeight = 0;

 for (var i = 0; i < mainDivs.length; ++i) {
  mainDivs[i].removeAttribute("style");
 }

  for (var i = 0; i < mainDivs.length; ++i) {
    if (maxHeight < mainDivs[i].clientHeight) {
      maxHeight = mainDivs[i].clientHeight;
    }
  }

  for (var i = 0; i < mainDivs.length; ++i) {
    mainDivs[i].style.height = maxHeight + "px";
  }

}

window.onload = function() {
  adjustHeightToMax(".trio__title");
  adjustHeightToMax(".trio__txt");
};

  window.addEventListener("resize", resizeThrottler, false);

  var resizeTimeout;
  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if ( !resizeTimeout ) {
      resizeTimeout = setTimeout(function() {
        resizeTimeout = null;
    adjustHeightToMax(".trio__title");
       adjustHeightToMax(".trio__txt");

       // The actualResizeHandler will execute at a rate of 15fps
       }, 66);
    }
  }
svg4everybody();